import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { IconButton } from "@material-tailwind/react";
import isEmpty from "utilities/isEmpty";

export default function LGRightArrow({ onClick, ...rest }) {
    const { visible, className } = { ...rest };
    const {
        onMove,
        carouselState: { currentSlide, deviceType }
    } = rest;

    const handleOnclick = (e) => {
        e.preventDefault();

        onClick(e);
    }

    return <IconButton
        key={"space_right_arrow"}
        className={`!right-1 !top-[45%] !px-[5px] !py-0 !translate-y-[-10px] !absolute  rounded-full bg-base-100 w-11 h-11 ${!visible ? "invisible group-hover:visible" : ""} ${!isEmpty(className) ? className : ""}`}
        onClick={handleOnclick}>
        <ChevronRightIcon
            strokeWidth={2}
            className="w-7 h-7 text-base-content"
        />
    </IconButton>;
};