/* eslint-disable @next/next/no-img-element */
"use client"
import {
  MapPinIcon,
  // HeartIcon,
  StarIcon,
} from '@heroicons/react/24/solid';
import { HeartIcon } from '@heroicons/react/24/outline';
// import { Chip, IconButton } from '@material-tailwind/react';
import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import stylesheet if you're not already using CSS @import

import Link from 'next/link';
import { BaseVerticalCard } from './BaseVerticalCard';
import { Chair } from '../svg/Chair';
import { Person } from '../svg/Person';
import isEmpty from 'utilities/isEmpty';
import Image from 'next/image';
import { Chip, IconButton } from '@material-tailwind/react';
import { SuperVenue } from '../svg/SuperVenue';
import CustomLeftArrow from '../carousel/CustomLeftArrow';
import CustomRightArrow from '../carousel/CustomRightArrow';
import localiseCurrency from 'utilities/localiseCurrency';
import { PricingTypes, RatePeriods, RateTypes, SearchLocationSourceTypes } from 'utilities/constants';
import SearchBuilder from 'app/search/(utils)/SearchBuilder';

//This is a fix for janky animations in scroll with react multi carousel
//see https://github.com/akiran/react-slick/issues/1240
let firstClientX, clientX;
const preventTouch = e => {
  const minValue = 5; // threshold

  clientX = e.touches[0].clientX - firstClientX;

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    e.preventDefault();
    e.returnValue = false;

    return false;
  }
};
const touchStart = e => {
  firstClientX = e.touches[0].clientX;
};

export function LocationCard(props) {
  const { disable, data } = props;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const numberOfImagesToPreload = 3;

  let containerRef = useRef();

  useEffect(() => {
    //We do this to prevent client errors
    setIsTouchDevice(getIsTouchDevice());

    if (containerRef.current) {
      containerRef.current.addEventListener("touchstart", touchStart);
      containerRef.current.addEventListener("touchmove", preventTouch, {
        passive: false
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("touchstart", touchStart);
        containerRef.current.removeEventListener("touchmove", preventTouch, {
          passive: false
        });
      }
    };



  }, []);

  const getIsTouchDevice = () => {
    return (typeof window != "undefined" && (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0)));
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Link
      href={SearchBuilder.GenerateFullPath({ locationId: data?.id, locationSourceId: SearchLocationSourceTypes.LOCAL, locationName: data?.locationName })}
      className="no-underline group" suppressHydrationWarning={true}>
      <BaseVerticalCard className="image-full">
        <figure className={'not-prose '} ref={containerRef}>
          <div className='bg-gray-300 aspect-[350/400] flex-auto select-none w-full relative'>
            {!isEmpty(data.locationPhotos) && data.locationPhotos.length > 0 && <div><img draggable="false" className='w-full' src={`${data.locationPhotos[0].url}?width=350&height=400&rmode=crop&scale=both&quality=100`} alt='AWE' /></div>}
            <div className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-gradient-to-t from-black/80 via-black/10 to-transparent w-full" />
          </div>
        </figure>
        <div className="card-body prose justify-end">
          <h3 className="card-title justify-center text-white">{data.locationName}</h3>
          <p className='max-h-9 text-white text-center'>{data.subtitle}</p>
        </div>
      </BaseVerticalCard>
    </Link>
  );
}
