"use client"
import { useEffect, useRef, useState } from "react";
import { LocationCard } from "../cards/LocationCard";

import CustomLeftArrow from "./CustomLeftArrow";
import CustomRightArrow from "./CustomRightArrow";
import Carousel from "react-multi-carousel";
import LGLeftArrow from "./LGLeftArrow";
import LGRightArrow from "./LGRightArrow";

export default function LocationCarousel({ data }) {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    let containerRef = useRef();

    useEffect(() => {
        //We do this to prevent client errors
        setIsTouchDevice(getIsTouchDevice());

        if (containerRef.current) {
            containerRef.current.addEventListener("touchstart", touchStart);
            containerRef.current.addEventListener("touchmove", preventTouch, {
                passive: false
            });
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener("touchstart", touchStart);
                containerRef.current.removeEventListener("touchmove", preventTouch, {
                    passive: false
                });
            }
        };



    }, []);

    const getIsTouchDevice = () => {
        return (typeof window != "undefined" && (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0)));
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    return <div >
        <Carousel
            afterChange={(previousSlide, { currentSlide, onMove }) => {
                setCurrentSlideIndex(currentSlide);
            }}
            swipeable={isTouchDevice}
            draggable={false}
            responsive={responsive}
            ssr
            infinite
            containerClass="card-carousel"
            itemClass="card-carousel-item"
            deviceType={"desktop"}
            arrows={true}
            // customTransition="transform 500ms linear"
            // transitionDuration={500}
            partialVisible={false}
            customLeftArrow={<LGLeftArrow visible={true} />}
            customRightArrow={<LGRightArrow visible={true} />}
        >
            {data?.map((homeLocation, index) => <LocationCard key={`location_card_${index}`} data={homeLocation} />)}
        </Carousel>

    </div>
};