import qs from 'qs';
import Paths from '../../../lib/routes/Paths';
import { SearchLocationSourceTypes } from 'utilities/constants';

class SearchBuilder {

    static EventTypeChange(search, { eventTypeId, eventTypeName }) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.eventTypeId = eventTypeId;
        queryObject.eventTypeName = eventTypeName;

        return this.GenerateFullPath(queryObject);
    }

    static PageChange(search, { page }) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.page = page;

        return this.GenerateFullPath(queryObject);
    }

    static LocationMapBoundsChange(search, { topLeftLat, topLeftLng, bottomRightLat, bottomRightLng }) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });
        queryObject.locationId = null;
        queryObject.locationName = null;
        queryObject.locationSourceId = SearchLocationSourceTypes.MAP;
        queryObject.topLeftLat = topLeftLat;
        queryObject.topLeftLng = topLeftLng;
        queryObject.bottomRightLat = bottomRightLat;
        queryObject.bottomRightLng = bottomRightLng;

        return this.GenerateFullPath(queryObject);
    }

    static LocationChange(search, { locationId, locationSourceId, locationName }) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.topLeftLat = null;
        queryObject.topLeftLng = null;
        queryObject.bottomRightLat = null;
        queryObject.bottomRightLng = null;

        queryObject.locationId = locationId;
        queryObject.locationSourceId = locationSourceId;
        queryObject.locationName = locationName;


        return this.GenerateFullPath(queryObject);
    }

    static GenerateFullPath({ eventTypeId, eventTypeName, guests, locationId, locationSourceId, locationName, topLeftLat, topLeftLng, bottomRightLat, bottomRightLng, page }) {
        const search = {
            eventTypeId: eventTypeId,
            eventTypeName: eventTypeName,
            guests: guests,
            locationId: locationId,
            locationSourceId: locationSourceId,
            locationName: locationName,
            topLeftLat: topLeftLat,
            topLeftLng: topLeftLng,
            bottomRightLat: bottomRightLat,
            bottomRightLng: bottomRightLng,
            page: page
        };

        const queryString = qs.stringify(search, { allowEmptyArrays: true, encodeValuesOnly: true, skipNulls: true });

        if (queryString) {
            return `${Paths.SEARCH.ROOT}?${queryString}`;
        }
        return Paths.SEARCH.ROOT;
    }
}

export default SearchBuilder;