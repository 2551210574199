import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { IconButton } from "@material-tailwind/react";
import isEmpty from "utilities/isEmpty";

export default function LGLeftArrow({ onClick, ...rest }) {
    const { visible, className } = { ...rest };
    const {
        onMove,
        carouselState: { currentSlide, deviceType }
    } = rest;

    const handleOnclick = (e) => {
        e.preventDefault();

        onClick(e);
    }

    // onMove means if dragging or swiping in progress.
    return <IconButton
        key={"space_left_arrow"}
        className={`!left-1 !top-[45%] !px-[5px] !py-0 !translate-y-[-10px] !absolute  rounded-full bg-base-100  w-11 h-11 ${!visible ? "invisible group-hover:visible" : ""} ${!isEmpty(className) ? className : ""}`}
        onClick={handleOnclick}>
        <ChevronLeftIcon
            strokeWidth={2}
            className="h-7 w-7 text-base-content"
        />
    </IconButton>;
};